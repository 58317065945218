.footer {
	border-top: 20px solid rgba(0, 123, 130, 1);
	border-bottom: 20px solid rgba(0, 123, 130, 1);
	padding-left: 0px !important;
	padding-right: 0px !important;
	user-select: none;
	background-color: rgba(0, 123, 130, 1);
}

.logoFooter {
	height: 35vh;
	min-height: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: rgba(0, 123, 130, 1);
	padding-top: 13vh;
}
.logo {
	height: calc(2rem + 2vw);
	transition: all 0.5s ease;
}
.empresaFooter {
	height: 35vh;
	display: flex;
	justify-content: center;
	min-height: 400px;
	background-color: transparent;
}
.servicioFooter {
	height: 35vh;
	display: flex;
	justify-content: center;
	min-height: 400px;
	background-color: transparent;
}
.contactoFooter {
	height: 35vh;
	display: flex;
	justify-content: center;
	min-height: 400px;
	background-color: transparent;
}
.list-footer {
	list-style: none;
	font-size: calc(.4rem + .4vw);
	font-family: GilroyLight;
	display: flex;
	flex-direction: column;
	background-color: transparent;
	padding-top: 12vh;
	color: white;
}
.item-list-footer {
	color: white;
}
.item-list-footer:hover {
	color: white;
	text-decoration: none;
}
.list-footer li .item-list-footer,
.list-footer li .item-list-footer:after,
.list-footer li .item-list-footer:before {
	transition: all .5s;
}

.list-footer li .item-list-footer {
	position: relative;
	cursor: pointer;
}
.list-footer li .item-list-footer:after {
	position: absolute;
	bottom: -3px;
	left: 0;
	right: 0;
	margin: auto;
	width: 0%;
	content: '.';
	color: transparent;
	background: white;
	height: 2px;
}
.list-footer li .item-list-footer:hover:after {
	width: 100%;
}
.icono-list-footer {
	height: calc(0.6rem + 0.6vw);
	margin: 8%;
	font-style: normal;
	transition: 0.5s ease;
}

.normal {
	transition: 0.5s ease;
}

.openClick {
	transform: rotate(90deg);
	transition: 0.5s ease;
}
.normal1 {
	transition: 0.5s ease;
}

.openClick1 {
	transform: rotate(90deg);
	transition: 0.5s ease;
}
.acordeonFooter {
	height: 25vh;
}

.logo:hover {
	transform: scale(1.25);
}

.icono-list-footer:hover {
	transform: scale(1.25);
	cursor: pointer;
}
/*--------------------------------MANEJANDO EL COMPORTAMIENTO A PARTIR DEL TAMAÑO DE PANTALLA --------------------------------*/
/*IPHONE 5S*/
@media (max-width: 320px) {
	.footer {
	}
	.logoFooter {
		height: 50vh !important;
		min-height: 0px;
		margin: none;
		padding-top: 20vh;
		background-color: transparent;
	}
	.logo {
		height: calc(3rem + 3vw);
		background-color: transparent;
	}
	.title-footer {
		color: white !important;
		font-family: GilroyLight !important;
		font-size: calc(1rem + 1vw) !important;
	}
	.icono-list-footer {
		height: calc(0.9rem + 0.9vw);
		margin: 14px;
	}
}
/*SAMSUNG S10 NOTE210 IPHONE 6S 6SPLUS X */

@media (min-width: 321px) and (max-width: 575px) {
	.footer {
	}
	.logoFooter {
		height: 50vh !important;
		min-height: 0px;
		margin: none;
		padding-top: 20vh;
		background-color: transparent;
	}
	.logo {
		height: calc(3rem + 3vw);
	}
	.title-footer {
		color: white !important;
		font-family: GilroyLight !important;
		font-size: calc(1rem + 1vw) !important;
	}
	.icono-list-footer {
		height: calc(0.9rem + 0.9vw);
		margin: 18px;
	}
}
/*TABLETS ANDROID */
@media (min-width: 576px) and (max-width: 767px) {
	.footer {
	}
	.logoFooter {
		height: 35vh;
	}
	.empresaFooter {
		height: 35vh;
	}
	.servicioFooter {
		height: 35vh;
	}
	.contactoFooter {
		height: 35vh;
	}
	.title-footer {
		color: white !important;
		font-family: GilroyLight !important;
	}
	.icono-list-footer {
		height: calc(0.9rem + 0.9vw);
		margin: 22px;
	}
}
/*IPAD, IPAD PRO  */

@media (min-width: 768px) and (max-width: 991px) {
	.footer {
	}
	.logoFooter {
		height: 30vh;
		padding-top: 15vh;
	}
	.empresaFooter {
		height: 30vh;
	}
	.servicioFooter {
		height: 30vh;
	}
	.contactoFooter {
		height: 30vh;
	}
	.list-footer {
		padding-top: 15vh;
	}
}
/*SIZE LG*/

@media (min-width: 992px) and (max-width: 1199px) {
	.footer {
	}
	.logoFooter {
		height: 40vh;
		padding-top: 20vh;
	}
	.empresaFooter {
		height: 40vh;
	}
	.servicioFooter {
		height: 40vh;
	}
	.contactoFooter {
		height: 40vh;
	}
	.list-footer {
		padding-top: 20vh;
	}
}
/*SIZE XL*/

@media (min-width: 1200px) {
}
