.navbar-custom-dark {
	background-color: rgba(0, 0, 0, .4) !important;
	transition-timing-function: cubic-bezier(1, 0, 0, 1);
	transition: all 0.5s;
}

nav {
	position: fixed;
	z-index: 60;
	left: 0;
	right: 0;
	top: 0;
	padding: 0 2%;
	height: 60px;
	background-color: transparent;
	padding-left: 12vw;
	padding-right: 12vw;
	transition: all 1s ease;
}
.containerLogo1 {
	display: none;
	height: 60px;
}
.imgLogoNav {
	height: 40px;
}

nav .links {
	float: center;
	padding: 0;
	margin: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	color: white;
	border-bottom: 1px solid white;
	z-index: 55;
	transition: all 1s ease;
}

nav .links li {
	list-style: none;
	font-family: "Montserrat-Regular";
}

nav .links a {
	display: block;
	padding: 1em;
	font-size: 20px;
	color: white !important;
	text-decoration: none;
}
.liNav {
	font-size: 16px !important;
	color: #ffff;
	outline: none;
	transition: 0.5s;
	cursor: pointer;
}
#nav-toggle {
	position: absolute;
	top: -100px;
}
nav .icon-burger {
	display: none;
	position: absolute;
	right: 5%;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
}

nav .icon-burger .line {
	width: 30px;
	height: 1.5px;
	background-color: white;
	margin: 7px;
	border-radius: 3px;
	transition: all .3s ease-in-out;
}
.liNav:hover {
	text-shadow: 0 0 20px white;
}
.active {
	text-shadow: 0 0 60px black;
}

@media screen and (max-width: 767px) {
	nav {
		background-color: red !important;
	}
	.navbar-custom {
		background-color: rgba(0, 0, 0, .3) !important;
	}
	.links li:nth-child(3) {
		display: none;
	}
	.containerLogo1 {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.imgLogo1 {
		height: 40px;
		display: block;
	}

	nav .links {
		float: none;
		position: fixed;
		left: 0;
		right: 0;
		top: 0px;
		bottom: 0%;
		flex-direction: column;
		width: auto;
		height: auto;
		opacity: 0;
		z-index: 55;
		visibility: hidden;
		align-items: center;
		justify-content: center;
		background-color: rgba(0, 0, 0, .7);
		overflow: hidden;
		transition: all .5s ease-in-out;
	}
	.liNav {
		font-size: 17px !important;
		font-weight: bold;
		font: #ffff;
		margin-top: 6%;
		margin-bottom: 6%;

	}
	nav :checked ~ .links {
		opacity: 1;
		visibility: visible;
		bottom: 0%; /*tiene que ser 0*/
	}

	nav .icon-burger {
		display: block;
		z-index: 57;
	}
	nav :checked ~ .icon-burger .line:nth-child(1) {
		transform: translateY(4px) rotate(45deg);
	}
	nav :checked ~ .icon-burger .line:nth-child(2) {
		transform: translateY(-4px) rotate(-45deg);
	}

}
