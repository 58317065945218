.containerQueOfrecemos {
  min-height: 100vh;
}

.colContainerItem {
  min-height: 30vh;
  width: 100%;
  padding-top: 2vh;
  padding-bottom: 2vh;
  padding-left: 10% !important;
  padding-right: 10% !important;
}
.colImgItem {
  min-height: 8vh;
  color: #707070;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colTextoItem {
  display: flex;
  flex-direction: column;
  justify-content: start;
  color: #707070;
  padding-top: 1% !important;
  text-align: justify;
  text-align-last: center;
}
.imgItem {
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
  margin: 0 2%;
}
.imgItem:hover {
  scale: 1.3;
}
.tituloItem {
  font-weight: bold;
  font-size: calc(0.8rem + 0.8vw);
  margin: 2vh auto;
  text-align: center;
}
.cuerpoItem {
  font-size: calc(0.5rem + 0.5vw);
}

@media (max-width: 320px) {
  .colContainerItem {
  }
  .colImgItem {
    justify-content: center;
    text-align: center;
  }

  .colTextoItem {
  }
  .tituloItem {
    width: auto;
  }
  .imgItem {
    height: 30px;
    text-align: center;
  }
  .cuerpoItem {
    font-size: calc(0.5rem + 0.5vw);
  }
}

/*SAMSUNG S10 NOTE210 IPHONE 6S 6SPLUS X */

@media (min-width: 321px) and (max-width: 575px) {
  .colContainerItem {
    height: 40vh;
  }
  .colImgItem {
  }
  .colTextoItem {
  }
  .imgItem {
    margin: 0px !important;
  }
  .tituloItem {
    padding: 0px 5% !important;
    text-align: start;
    width: 60%;
  }
  .cuerpoItem {
    font-size: calc(0.75rem + 0.75vw);
  }
}
/*TABLETS ANDROID IPAD*/
@media (min-width: 576px) and (max-width: 767px) {
  .colImgItem {
    justify-content: center;
    text-align: center;
  }
  .colTextoItem {
  }
}
/*TABLETS IPAD PRO IPAD PRO MAX*/

@media (min-width: 768px) and (max-width: 991px) {
}
/*SIZE LG*/
@media (min-width: 992px) and (max-width: 1199px) {
}
/*SIZE XL*/

@media (min-width: 1200px) {
}
