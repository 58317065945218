.cardNoticia {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	padding-top: 0px;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background-color: rgba(0, 0, 0, .1);
	border: .5px solid white;
	box-shadow: #007b82;
}
.cardNoticia:after {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, .1);
	background-color: rgba(0, 123, 130, .5);
	clip-path: polygon(0% 100%, 100% 100%, 100% 60%, 0% 88%);
	z-index: 10;
}

.iconoMsjWp {
	position: absolute;
	bottom: 125px;
	right: 30px;
	width: 55px;
	height: 55px;
	z-index: 40;
	cursor: pointer;
}
.videoPortada {
	position: absolute;
	min-width: 100%;
	height: 115vh;
	background-color: black;
}
.tituloPortada {
	font-family: 'Segoe UI';
	font-weight: bold;
}
/* Iphone 5S, dispositivos pequeños*/
@media (max-width: 320px) {
	.iconoMsjWp {
		bottom: 125px;
		right: 12px;
	}
}
/*SAMSUNG S10 NOTE210 IPHONE 6S 6SPLUS X */
@media (min-width: 321px) and (max-width: 576px) {
	.iconoMsjWp {
		bottom: 125px;
		right: 10px;
	}
}
/* TABLETS ANDROID IPAD */
@media (min-width: 577px) and (max-width: 768px) {
	.iconoMsjWp {
		bottom: 125px;
		right: 20px;
	}
}
/* TABLETS IPAD PRO IPAD PRO MAX */
@media (min-width: 769px) and (max-width: 992px) {
}
/* SIZE LG */
@media (min-width: 993px) and (max-width: 1200px) {
}
/* SIZE XL */
@media (min-width: 1201px) {
}
