.containerServ {
  height: 20vh !important;
  padding: 0px !important;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  overflow: hidden;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin: 0px;
  background-color: black;
}
/* .containerServ:nth-child(1) {
	background-image: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)),
		url("../../assets/images/derecho-civil.jpg");
}
.containerServ:nth-child(2) {
	background-image: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)),
		url("../../assets/images/derecho-sucesorio.jpg");
}
.containerServ:nth-child(3) {
	background-image: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)),
		url("../../assets/images/derecho-consumidor.jpg");
}

.containerServ:nth-child(4) {
	background-image: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)),
		url("../../assets/images/derecho-laboral.jpg");
}
.containerServ:nth-child(5) {
	background-image: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)),
		url("../../assets/images/derecho-previsional.jpg");
}
.containerServ:nth-child(6) {
	background-image: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)),
		url("../../assets/images/derecho-penal.jpg");
}
.containerServ:nth-child(7) {
	background-image: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)),
		url("../../assets/images/derecho-empresarial\ \(2\).jpg");
}
.containerServ:nth-child(8) {
	background-image: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)),
		url("../../assets/images/derecho-consumidor\ \(2\).jpg");
}
.containerServ:nth-child(9) {
	background-image: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)),
		url("../../assets/images/derecho-previsional\ \(2\).jpg");
}
.containerServ:nth-child(10) {
	background-image: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)),
		url("../../assets/images/derecho-familiar.jpg");
} */
.descripcionServ {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 123, 130, 0.7);
  width: 100%;
  height: 100%;
  transition: all 0.7s ease-in-out;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-size: calc(0.5rem + 0.5vw);
  user-select: none;
}
.leerMas {
  transition: all 0.7s ease-in-out;
  color: transparent;
  position: absolute;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
  z-index: 3;
}
.imgDerecho {
  cursor: pointer;
  transition: 0.85s all ease-in-out;
}
.textoDerecho {
  font-family: GilroyLight;
  font-size: 1.875rem;
  color: white;
  text-align: center;
  transition: all 0.2s ease-in-out;
  z-index: 2;
  user-select: none;
}
.modal-content {
  border-radius: 0px !important;
}
/*IPHONE 5S*/
@media (max-width: 320px) {
  .leerMas {
    color: white;
    right: 20px;
    bottom: 10px;
  }
  .textoDerecho {
    font-size: 20px;
  }
}

/*SAMSUNG S10 NOTE210 IPHONE 6S 6SPLUS X */

@media (min-width: 321px) and (max-width: 575px) {
  .leerMas {
    color: white;
  }
  .textoDerecho {
    font-size: 20px;
  }
}
/*TABLETS ANDROID IPAD*/
@media (min-width: 576px) and (max-width: 767px) {
  .leerMas {
    color: white;
  }
}

/*TABLETS IPAD PRO IPAD PRO MAX*/

@media (min-width: 768px) and (max-width: 991px) {
  .leerMas {
    color: white;
  }
  .containerServ:nth-child(1) {
  }
  .containerServ:nth-child(2) {
  }
  .containerServ:nth-child(3) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .containerServ:nth-child(4) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .containerServ:nth-child(5) {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .containerServ:nth-child(6) {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .containerServ:nth-child(7) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .containerServ:nth-child(8) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .containerServ:nth-child(9) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .containerServ:nth-child(10) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .textoDerecho {
    font-size: 1.175rem;
  }
}
/*SIZE LG*/
@media (min-width: 992px) and (max-width: 1199px) {
  .containerServ:nth-child(1) {
  }
  .containerServ:nth-child(2) {
  }
  .containerServ:nth-child(3) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .containerServ:nth-child(4) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .containerServ:nth-child(5) {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .containerServ:nth-child(6) {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .containerServ:nth-child(7) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .containerServ:nth-child(8) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .containerServ:nth-child(9) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .containerServ:nth-child(10) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .textoDerecho {
    font-size: 1.575rem;
  }
}
/*SIZE XL*/

@media (min-width: 1200px) {
  .containerServ:hover .leerMas {
    color: white;
  }
  .imgDerecho:hover {
    width: 120% !important;
  }
  .containerServ:nth-child(1) {
  }
  .containerServ:nth-child(2) {
  }
  .containerServ:nth-child(3) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .containerServ:nth-child(4) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .containerServ:nth-child(5) {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .containerServ:nth-child(6) {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .containerServ:nth-child(7) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .containerServ:nth-child(8) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .containerServ:nth-child(9) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .containerServ:nth-child(10) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
