* {
	font-family: GilroyLight;
}

.section3 {
	margin-top: 5vh;
	display: flex;
	flex-direction: column;
}
.rowFormContacto {
	display: flex;
	justify-content: center;
	width: 100%;
	margin: 0px !important;
	background-color: transparent;
	padding-left: 10vw;
	padding-right: 10vw;
}
.sub-titulo {
	font-family: GilroyLight;
	text-align: center;
	width: 50vw;
	background-color: transparent;
	font-size: calc(1rem + 1vw);
	color: #423e37;
}
.section4 {
	margin-top: 5vh;
	background-color: transparent;
}

.titulo {
	font-family: GilroyLight;
	font-size: calc(1.7rem + 1.7vw);
	color: rgba(0, 123, 130, 1);
	background-color: transparent;
	text-align: center;
}

.divider {
	width: 45vw;
	align-self: center;
	margin-top: 10vh;
	border: .5px solid rgba(66, 62, 55, .5);
}

/*--------------------------------MANEJANDO EL COMPORTAMIENTO A PARTIR DEL TAMAÑO DE PANTALLA --------------------------------*/
/*IPHONE 5S*/
@media (max-width: 320px) {
}

/*SAMSUNG S10 NOTE210 IPHONE 6S 6SPLUS X */

@media (min-width: 321px) and (max-width: 576px) {
}
/*TABLETS ANDROID IPAD*/
@media (min-width: 577px) and (max-width: 768px) {
}
/*TABLETS IPAD PRO IPAD PRO MAX*/

@media (min-width: 769px) and (max-width: 992px) {
}
/*SIZE LG*/
@media (min-width: 993px) and (max-width: 1200px) {
}
/*SIZE XL*/

@media (min-width: 1026px) {
}
