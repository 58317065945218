body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
@font-face {
	font-family: 'GilroyLight';
	src: local('GilroyLight'), url("./assets/fonts/Gilroy-Light.otf") format('truetype');
}
@font-face {
	font-family: 'GilroyExtraBold';
	src: local('GilroyExtraBold'), url("./assets/fonts/Gilroy-ExtraBold.otf") format('truetype');
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
