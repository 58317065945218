.containerNoticiaIndividual {
	padding-top: 60px;
	min-height: 600px;
	/* background-color: salmon; */
}
.colImagenNoticia {
	height: 400px;
	/* background-color: aqua; */
}
.colTextoNoticia {
	min-height: 400px;
	/* background-color: red; */
}

@media (max-width: 320px) {
	.containerNoticiaIndividual{
	}
	.colImagenNoticia {
		/* height: 30vh; */
	}
	.colTextoNoticia {
		height: 100%;
	}
}
@media (min-width: 321px) and (max-width: 576px) {
	.colImagenNoticia {
		/* height: 30vh; */
	}
	.colTextoNoticia {
		height: 100%;
	}
}
@media (min-width: 577px) and (max-width: 768px) {
	.colImagenNoticia {
	}
	.colTextoNoticia {
		height: 100%;
	}
}
