.containerQuienes {
	height: 100vh;
	display: block;
	padding: 0px !important;
	position: relative;
}

.containerTxtQuienes {
	clip-path: polygon(0 100%, 100% 76%, 100% 0, 0 0);
	background-image: url("../../assets/images/galeria/galeria6.jpg");
	background-position-y: -5rem;
	background-size: 100%;
	background-repeat: no-repeat;
	width: 100%;
	height: 42vh;
	filter: blur(0px)opacity(30%);
	background-color: black;
	transition: .865s all ease;
}

.containerTxtInfo {
	width: 100%;
	height: 65vh;
	background-color: #fffaf4;
	display: flex;
	justify-content: center;
}
.tituloTextoQuienes {
	position: absolute;
	font-size: calc(2rem + 2vw);
	top: 12vh;
	left: 15vw;
	text-align: start;
	font-family: GilroyLight;
	color: white;
	font-weight: bold;
	z-index: 30;
	user-select: none;
}
.subtituloTextoQuienes {
	user-select: none;
	margin-left: 15vw;
	margin-top: 10vh;
	padding-left: 12vh;
	font-size: calc(1rem + 1vw);
	text-align: end;
	font-family: GilroyLight;
	width: 50vw;
	color: #707070;
}
@media (max-width: 320px) {
	.containerTxtQuienes {
		background-position-y: 0px;
		background-position-x: center;
		filter: blur(0px) opacity(80%);
		background-size: 140%;
	}
	.tituloTextoSection1 {
		padding-left: 0px;
		align-self: center;
	}
	.subtituloTextoQuienes {
		align-self: center;
		width: 90%;
		padding-right: 5vw;
		margin-top: -8vh;

	}
	.containerImagenSection1 {
		display: none;
	}
	.sub-titulo {
		width: 100vw;
	}
	.textoInfoSection5 {
		font-size: 20px !important;
	}
}
/*SAMSUNG S10 NOTE210 IPHONE 6S 6SPLUS X */
@media (min-width: 321px) and (max-width: 575px) {
	.containerTxtQuienes {
		background-position-y: 0px;
		background-position-x: center;
		filter: blur(0px) opacity(80%);
		background-size: 170%;
		height:35vh;
	}
	.tituloTextoSection1 {
		padding-left: 0px;
		align-self: center;
	}
	.subtituloTextoQuienes {
		align-self: center;
		width: 90%;
		padding-right: 5vw;
		margin-top: -8vh;
	}
	.containerImagenSection1 {
		display: none;
	}
	.sub-titulo {
		width: 100vw;
	}
}
/* TABLETS ANDROID IPAD */
@media (min-width: 576px) and (max-width: 767px) {
	.containerTxtQuienes {
		background-position-y: 0px;
		background-position-x: center;
		filter: blur(0px) opacity(80%);
		background-size: 140%;
	}
	.tituloTextoSection1 {
		padding-left: 0px;
		align-self: center;
	}
	.subtituloTextoQuienes {
		align-self: center;
		width: 90%;
	}
	.containerImagenSection1 {
		display: none;
	}
}
/* TABLETS IPAD PRO IPAD PRO MAX */
@media (min-width: 768px) and (max-width: 991px) {
	.containerTxtQuienes {
		background-position-y: 0px;
	}
}
/* SIZE LG */
@media (min-width: 992px) and (max-width: 1199px) {
	.containerTxtQuienes {
		background-position-y: 0px;
	}
}
/* SIZE XL */
@media (min-width: 1200px) {
	.containerTxtQuienes:hover {
		filter: blur(20px) opacity(20%);
	}
}
