.btn-enviar {
	background-color: transparent !important;
	color: rgba(0, 123, 130, 1) !important;
	border: 1.5px solid rgba(0, 123, 130, 1) !important;
	font-weight: bolder !important;
}

.btn-enviar:focus {
	box-shadow: 0px 0px 8px 4px rgba(0, 123, 130, .41) !important;
}

.form-group input {
	box-shadow: none !important;
}

.btn-login {
	background-color: rgba(0, 123, 130, 1) !important;
	color: white !important;
	width: 100%;
	border: 1.5px solid rgba(0, 123, 130, 1) !important;
	font-weight: bolder !important;
}

.btn-login:focus {
	box-shadow: 0px 0px 8px 4px rgba(0, 123, 130, .41) !important;
}

.section3 {
}
