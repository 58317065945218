.navbar-custom-dark2 {
	background-color: white !important;
	transition-timing-function: cubic-bezier(1, 0, 0, 1);
	transition: all 0.5s;
}

nav {
	position: fixed;
	z-index: 50;
	left: 0;
	right: 0;
	top: 0;
	padding: 0 2%;
	height: 60px;
	background-color: transparent;
	padding-left: 12vw;
	padding-right: 12vw;
	transition: all 1s ease;
}
.imgLogo {
	height: 40px;
}
.imgLogoWhite {
	height: 35px;
	display: none;
}
nav .logo {
	display: flex;
	align-items: center;
}
nav .logo1 {
	float: left;
	height: 100%;
	display: flex;
	align-items: center;
	display: none;
}
nav .linkss {
	float: center;
	padding: 0;
	margin: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	color: white;
	border-bottom: 1px solid black;
	z-index: 55;
	transition: all 1s ease;
}

nav .linkss li {
	list-style: none;
	font-family: "Montserrat-Regular";
}

nav .linkss a {
	display: block;
	padding: 1em;
	font-size: 20px;
	color: black !important;
	text-decoration: none;
}
.liNav {
	font-size: 16px !important;
	color: #ffff;
	outline: none;
	transition: 0.5s;
	cursor: pointer;
}
#nav-toggle {
	position: absolute;
	top: -100px;
}
nav .icon-burger2 {
	display: none;
	position: absolute;
	right: 5%;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
}

nav .icon-burger2 .line {
	width: 30px;
	height: 1.5px;
	background-color: black;
	margin: 7px;
	border-radius: 3px;
	transition: all .3s ease-in-out;
}
.liNav:hover {
	text-shadow: 0 0 10px white;
}
.active {
	text-shadow: 0 0 60px black;
}

@media screen and (max-width: 768px) {
	.navbar-custom-dark2 {
		background-color: white !important;
	}
	.navbar-custom2 {
		background-color: white !important;
	}
	.linkss li:nth-child(3) {
		display: none;
	}
	.imgLogo {
		height: 30px;
		display: block;
	}
	.imgLogo1 {
		height: 30px;
		display: block;
	}
	nav .logo {
		float: none;
		width: 100%; /*auto*/
		justify-content: center;
		padding-left: 0px;
	}
	nav .logo1 {
		width: 100%; /*auto*/
		justify-content: center;
		padding-left: 0px;
		display: flex;
	}
	nav .linkss {
		float: none;
		position: fixed;
		z-index: 9;
		left: 0;
		right: 0;
		top: 0px;
		bottom: 0%;
		flex-direction: column;
		width: auto;
		opacity: 0;
		visibility: hidden;
		height: auto;
		justify-content: center;
		background-color: white;
		overflow: hidden;
		transition: all .5s ease-in-out;
		font: black;
		border-bottom: none;

	}
	.liNav {
		font-size: 17px !important;
		font-weight: bold;
		margin-top: 6%;
		margin-bottom: 6%;
	}
	nav :checked ~ .linkss {
		opacity: 1;
		visibility: visible;
		bottom: 0%; /*tiene que ser 0*/
	}
	nav .icon-burger2 {
		display: block;
		z-index: 57;

	}
	nav :checked ~ .icon-burger2 .line:nth-child(1) {
		transform: translateY(4px) rotate(45deg);
	}
	nav :checked ~ .icon-burger2 .line:nth-child(2) {
		transform: translateY(-4px) rotate(-45deg);
	}

}
