.tituloPrincipal {
	font-size: calc(0.9rem + 0.9vw);
	font-weight: bold;
	text-align: start;
	margin-top: 3vh;
	margin-bottom: 3vh;
}
.imgNoticia {
	background-image: url("../../assets/images/derecho-civil.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	height: 100%;
	width: 100%;
}
.tituloNoticia {
	font-family: GilroyLight;
	font-size: calc(0.7rem + 0.7vw);
	text-align: start;
}
.fechaNoticia {
	font-family: GilroyLight;
	font-size: calc(0.6rem + 0.6vw);
	align-self: start;
}

.sectionA {
	background-color: transparent;
	min-height: 250vh;
}
.shapeGris {
	position: absolute;
	height: 250%;
	width: 100%;
	background-color: rgba(112, 112, 112, .19);
	clip-path: polygon(0 0, 0 100%, 21% 26%);
	left: 0;
}
.shapeVerde {
	position: absolute;
	height: 200%;
	width: 95%;
	right: 0;
	bottom: -150%;
	background-color: rgba(0, 123, 130, .19);
	clip-path: polygon(100% 0, 100% 100%, 74% 75%);
}
.colNoticiaSuperiorImagen {
	height: 20vh;
	display: flex;
	padding-left: 0px;
	padding-right: 0px;
	background-color: cadetblue;
}
.colNoticiaSuperiorTexto {
	height: 18vh;
	display: flex;
	overflow: hidden;
	/* background-color:burlywood */
}
.tituloNoticiaSuperior {
	min-height: 40%;
	font-size: calc(0.55rem + 0.55vw);
	font-weight: bolder;
	word-break: break-word;
	overflow: hidden;
	}
.subTituloNoticiaSuperior {
	min-height: 60%;
	font-size: calc(0.45rem + 0.45vw);
	word-break: break-word;
	/* white-space: nowrap; */
	/* text-overflow: ellipsis; */
}
.colNoticiaMedioImagen {
	height: 35vh;
	display: flex;
	padding-left: 0px;
	padding-right: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
}
.colNoticiaMedioTexto {
	height: 35vh;
	display: flex;
	align-items: center;
}
.tituloNoticiaMedio {
	font-size: calc(0.8rem + 0.8vw);
	font-weight: bolder;
}
.subTituloNoticiaMedio {
	font-size: calc(0.65rem + 0.65vw);
}
.colNoticiaInferiorImagen {
	height: 40vh;
	display: flex;
	justify-content: center;
	padding-right: 0px;
	padding-top: 20px;
	padding-bottom: 0px;
}
.colNoticiaInferiorTexto {
	height: 40vh;
	display: flex;
	align-items: center;
}
.tituloNoticiaInferior {
	font-size: calc(0.8rem + 0.8vw);
	font-weight: bolder;
	color: teal;
}
.subTituloNoticiaInferior {
	font-size: calc(0.65rem + 0.65vw);
}

.colNoticiaInferior {
	height: 50vh;
	display: flex;
	padding-top: 15px;
	padding-bottom: 15px;
}

@media (max-width: 320px) {
	.colNoticiaSuperiorImagen {
		height: 25vh;
	}
	.colNoticiaSuperiorTexto {
		height: 25vh;
	}
	.tituloNoticiaSuperior {
		font-size: calc(1rem + 1vw);
		text-align: left;
	}
	.subTituloNoticiaSuperior {
		font-size: calc(0.8rem + 0.8vw);
		text-align: left;
	}

	.colNoticiaMedioImagen {
		height: 35vh;
	}
	.colNoticiaMedioTexto {
		height: 35vh;
	}
	.tituloNoticiaMedio {
		font-size: calc(1rem + 1vw);
		text-align: left;
	}
	.subTituloNoticiaMedio {
		font-size: calc(0.8rem + 0.8vw);
		text-align: left;
	}

	.colNoticiaInferiorImagen {
		height: 40vh;
	}
	.colNoticiaInferiorTexto {
		height: 30vh;
		padding-left: 0px !important;
	}
	.tituloNoticiaInferior {
		font-size: calc(1.1rem + 1.1vw);
		width: 100%;
	}
	.subTituloNoticiaInferior {
		font-size: calc(0.8rem + 0.8vw);
	}
}
@media (min-width: 321px) and (max-width: 575px) {
	.colNoticiaSuperiorImagen {
		height: 25vh;
	}
	.colNoticiaSuperiorTexto {
		height: 25vh;
	}
	.tituloNoticiaSuperior {
		font-size: calc(1rem + 1vw);
		text-align: left;
	}
	.subTituloNoticiaSuperior {
		font-size: calc(0.8rem + 0.8vw);
		text-align: left;
	}

	.colNoticiaMedioImagen {
		height: 35vh;
	}
	.colNoticiaMedioTexto {
		height: 35vh;
	}
	.tituloNoticiaMedio {
		font-size: calc(1rem + 1vw);
		text-align: left;
	}
	.subTituloNoticiaMedio {
		font-size: calc(0.8rem + 0.8vw);
		text-align: left;
	}

	.colNoticiaInferiorImagen {
		height: 40vh;
	}
	.colNoticiaInferiorTexto {
		height: 30vh;
		padding-left: 0px !important;
	}
	.tituloNoticiaInferior {
		font-size: calc(1rem + 1vw);
		text-align: left;
	}
	.subTituloNoticiaInferior {
		font-size: calc(0.8rem + 0.8vw);
		text-align: left;
	}
}
@media (min-width: 576px) and (max-width: 767px) {
	.colNoticiaSuperiorImagen {
		height: 35vh;
	}
	.colNoticiaSuperiorTexto {
		height: 35vh;
	}
	.tituloNoticiaSuperior {
		font-size: calc(1rem + 1vw);
		text-align: left;
	}
	.subTituloNoticiaSuperior {
		font-size: calc(0.8rem + 0.8vw);
		text-align: left;
	}
	.colNoticiaMedioImagen {
		height: 45vh;
	}
	.colNoticiaMedioTexto {
		height: 45vh;
	}
	.tituloNoticiaMedio {
		font-size: calc(1rem + 1vw);
		text-align: left;
	}
	.subTituloNoticiaMedio {
		font-size: calc(0.8rem + 0.8vw);
		text-align: left;
	}

	.colNoticiaInferiorImagen {
		height: 50vh;
	}
	.colNoticiaInferiorTexto {
		height: 50vh;
	}
	.tituloNoticiaInferior {
		font-size: calc(1rem + 1vw);
		text-align: left;
	}
	.subTituloNoticiaInferior {
		font-size: calc(0.8rem + 0.8vw);
		text-align: left;
	}
}
