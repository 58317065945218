.rowAbogados {
	padding-left: 15vw;
	padding-right: 15vw;
	padding-top: 8vh;
	background-color: transparent;
	justify-content: center;
}
.containerAbogados {
	display: flex;
	padding-left: 2.5vw;
	padding-right: 2.5vw;
	padding-bottom: 2.5vw;
	justify-content: center;
	align-items: center;
}
.containerImgAbogado {
	background-color: black;
}
.imgAbogado {
	position: relative;
	width: 19vw;
	height: 19vw;
	background-color: black;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
	cursor: pointer;
	transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
	/* -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.43);
	box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.43); */
}
.imgAbogado :nth-child(2) {
	background-position: auto -200px;
}
.containerInfoAbogado {
	background: linear-gradient(transparent, rgba(0, 123, 130, .6));
}
.nombreAbogado {
	position: absolute;
	text-transform: uppercase;
	padding: 7px;
	z-index: 5;
	top: 70%;
	left: -80%;
	color: #ffffff;
	font-weight: 300;
	width: 80%;
	-webkit-transition: all 0.7s;
	-moz-transition: all 0.7s;
	-ms-transition: all 0.7s;
	transition: all 0.7s;
	opacity: 0;
}
.tituloAbogado {
	position: absolute;
	padding: 5px;
	z-index: 5;
	top: 71%;
	right: -85%;
	margin-top: 25px;
	color: #ffffff;
	font-weight: 300;
	width: 80%;
	-webkit-transition: all 0.7s;
	-moz-transition: all 0.7s;
	-ms-transition: all 0.7s;
	transition: all 0.7s;
	opacity: 0;
}

/*--------------------------------MANEJANDO EL COMPORTAMIENTO A PARTIR DEL TAMAÑO DE PANTALLA --------------------------------*/
/*IPHONE 5S*/
@media (max-width: 320px) {
	.rowAbogados {
		padding: 0;
	}
	.imgAbogado {
		width: 80vw;
		height: 80vw;
	}
	.containerInfoAbogado {
		position: absolute;
		bottom: 0;
		height: 50%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content:flex-end;
		align-items: center;
	}
	.nombreAbogado {
		opacity: 1;
		position: static;
		text-align: center;
		width: auto;
		margin: 0;
	}
	.tituloAbogado {
		opacity: 1;
		position: static;
		text-align: center;
		width: auto;
		margin: 0;
	}
	.sub-titulo {
		width: 100vw;
	}
	.textoInfoSection5 {
		font-size: 20px !important;
	}
}

/*SAMSUNG S10 NOTE210 IPHONE 6S 6SPLUS X */

@media (min-width: 321px) and (max-width: 575px) {
	.rowAbogados {
		padding: 0;
	}
	.imgAbogado {
		width: 90vw;
		height: 90vw;
	}
	.containerInfoAbogado {
		position: absolute;
		bottom: 0;
		height: 50%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content:flex-end;
		align-items: center;
	}
	.nombreAbogado {
		opacity: 1;
		position: static;
		text-align: center;
		width: auto;
		margin: 0;
	}
	.tituloAbogado {
		opacity: 1;
		position: static;
		text-align: center;
		width: auto;
		margin: 0;
	}
	.sub-titulo {
		width: 100vw;
	}
}
/*TABLETS ANDROID IPAD*/
@media (min-width: 576px) and (max-width: 767px) {
	.rowAbogados{
		padding-left: 5vw;
		padding-right: 5vw;
	}
	.imgAbogado {
		width: 40vw;
		height: 40vw;
	}
	.containerInfoAbogado {
		position: absolute;
		bottom: 0;
		height: 50%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content:flex-end;
		align-items: center;
	}
	.nombreAbogado {
		opacity: 1;
		position: static;
		text-align: center;
		width: auto;
		margin: 0;
	}
	.tituloAbogado {
		opacity: 1;
		position: static;
		text-align: center;
		width: auto;
		margin: 0;
	}
}
/*TABLETS IPAD PRO IPAD PRO MAX*/

@media (min-width: 768px) and (max-width: 991px) {
	.rowAbogados{
		padding-left: 5vw;
		padding-right: 5vw;
	}
	.containerAbogados {
		display: flex;
		padding-left:1vw;
		padding-right:1vw;
		padding-bottom: 3vw;
	}

	.imgAbogado {
		width: 40vw;
		height: 40vw;
	}
	.containerInfoAbogado {
		position: absolute;
		bottom: 0;
		height: 50%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content:flex-end;
		align-items: center;
	}
	.nombreAbogado {
		opacity: 1;
		position: static;
		text-align: center;
		width: auto;
		margin: 0;
	}
	.tituloAbogado {
		opacity: 1;
		position: static;
		text-align: center;
		width: auto;
		margin: 0;
	}
}
/*SIZE LG*/
@media (min-width: 992px) and (max-width: 1199px) {
	.imgAbogado {
		filter: opacity(50%);
	}
}
/*SIZE XL*/

@media (min-width: 1200px) {
	.imgAbogado {
		filter: opacity(50%);
	}
	.imgAbogado:hover {
		filter: opacity(100%);
	}
	.imgAbogado:hover .nombreAbogado {
		background-color: rgba(0, 123, 130, 1);
		left: 0px;
		opacity: 1;
	}
	.imgAbogado:hover .tituloAbogado {
		background-color: rgba(66, 62, 55, .5);
		right: 0px;
		opacity: 1;
	}
}
