.containerGaleria {
	height: 140vh;
	min-height: 700px;
	padding-left: 0;
	padding-right: 0;
}
.grid-container {
	height: 130vh;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: 1.2fr 1.2fr 0.6fr;
	gap: 0px 0px;
	grid-template-areas: "A A A B B B B B B C C C" "D D D D E E E E F F F F" "G G G G H H H H F F F F";
}
.A {
	grid-area: A;
}
.B {
	grid-area: B;
}
.C {
	grid-area: C;
}
.D {
	grid-area: D;
}
.E {
	grid-area: E;
}
.F {
	grid-area: F;
}
.G {
	grid-area: G;
}
.H {
	grid-area: H;
}

.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 30;
	user-select: none;
}
.backdrop img {
	user-select: none;
	display: block;
	max-width: 80%;
	max-height: 80%;
	margin: 5rem auto;
	box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}
.imgGaleria {
	filter: opacity(50%);
	transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1) all;
}
.imgGaleria:hover {
	filter: opacity(100%);
}
