.section5 {
	margin: 0px;
	padding: 0px;
	height: 100%;
	background-color: transparent;
}
.infoSection5 {
	min-height: 50vh;
	background-color: rgba(0, 123, 130, 1);
	padding-top: 5vh;
	padding-bottom: 5vh;
	padding-left: 5vh;
}
.mapaSection5 {
	min-height: 50vh;
	background-color: #efefef;
}
.textoInfoSection5 {
	color: white;
	font-weight: normal;
	text-align: start;
}

/*--------------------------------MANEJANDO EL COMPORTAMIENTO A PARTIR DEL TAMAÑO DE PANTALLA --------------------------------*/
/*IPHONE 5S*/
@media (max-width: 320px) {
	.textoInfoSection5 {
		font-size: 20px !important;
	}
}

/*SAMSUNG S10 NOTE210 IPHONE 6S 6SPLUS X */

@media (min-width: 321px) and (max-width: 575px) {
	.textoInfoSection5 {
		font-size: 24px !important;
	}
}
/*TABLETS ANDROID IPAD*/
@media (min-width: 576px) and (max-width: 767px) {
	.infoSection5{
		padding-left: 0px;

	}
}
/*TABLETS IPAD PRO IPAD PRO MAX*/

@media (min-width: 768px) and (max-width: 991px) {
}
/*SIZE LG*/
@media (min-width: 992px) and (max-width: 1199px) {
}
/*SIZE XL*/

@media (min-width: 1200px) {
}
